/*
import React, { useState, createContext } from "react"

export const menuContext = createContext(false)

const MenuProvider = props => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  return (
    <menuContext.Provider value={{ open, toggleOpen }}>
      {props.children}
    </menuContext.Provider>
  )
}

export default MenuProvider
*/
import React, { createContext, useReducer } from "react"
import { MenuReducer } from "./menu-reducer"

// see for tutorial
//https://endertech.com/blog/using-reacts-context-api-for-global-state-management

const initialState = {
  bodyClass: "",
  openMenu: () => {},
  closeMenu: () => {},
}

export const MenuContext = createContext(initialState)

export const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MenuReducer, initialState)
  // Actions for changing state
  function openMenu() {
    dispatch({
      type: "OPEN_MENU",
    })
  }

  function closeMenu() {
    dispatch({
      type: "CLOSE_MENU",
    })
  }

  return (
    <MenuContext.Provider
      value={{
        bodyClass: state.bodyClass,
        openMenu,
        closeMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}
