export const MenuReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MENU":
      return {
        bodyClass: "",
      }
    case "CLOSE_MENU":
      return {
        bodyClass: "hidden",
      }
    default:
      return state
  }
}
